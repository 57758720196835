<template>
    <div :class="payload.customClass" v-html="htmlContent"></div>
</template>

<script setup>
const props = defineProps(['payload'])
const htmlContent = computed(() => props.payload?.config?.html)
</script>

<style scoped>

</style>